import {useState, useEffect} from 'react'

import {ScrollToTopButtonWrapper} from './ScrollToTopButton.styles'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import {scrollToTop} from '../../../utils/scrollToTop'

const ScrollToTopButton = () => {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(true)

  /**
   * Hide scroll to top button when self diagnosis toast is visible
   * as they overlap each other
   */
  useEffect(() => {
    const selfDiagnosisVisible = !!document.getElementById(
      'self-diagnosis-toast'
    )
    if (selfDiagnosisVisible) {
      setShowScrollToTopButton(false)
    }
  }, [])

  if (!showScrollToTopButton) return null

  return (
    <ScrollToTopButtonWrapper
      onClick={scrollToTop}
      className="scroll-to-top-button"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 19V5M12 5L5 12M12 5L19 12"
          stroke="var(--brand-primary-black-light-color-o5)"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </ScrollToTopButtonWrapper>
  )
}

export default withCustomErrorBoundary(ScrollToTopButton, 'ScrollToTopButton')
