import noop from 'lodash/noop'
import React from 'react'

import ArrowLeft from '../../../../assets/icons/ArrowLeftWhite.svg'
import ArrowRight from '../../../../assets/icons/ArrowRightWhite.svg'
import {ArrowIcon, RenderItemContainer} from './Slider.style'

const CarouselSliderComponent = (props: any) => {
  const {state, actions} = props || {}
  const {slides = [], currentSlide = 0} = state || {}
  const {
    handlePrev = noop,
    handleNext = noop,
    renderItem = noop,
    renderSideItem = noop,
  } = actions || {}
  return (
    <>
      {slides.length >= 2 ? (
        <>
          {slides.map((slide: VideoShowCaseSlide, index: number) => (
            <RenderItemContainer key={index} active={index === currentSlide}>
              {renderSideItem((index - 1 + slides.length) % slides.length)(
                slides[(index - 1 + slides.length) % slides.length]
              )}

              <div className="btn-icons">
                <div className="left-arrow" onClick={handlePrev}>
                  <ArrowIcon src={ArrowLeft} alt="left arrow" />
                </div>
                {renderItem(slide, index)}
                <div className="right-arrow" onClick={handleNext}>
                  <ArrowIcon src={ArrowRight} alt="right arrow" />
                </div>
              </div>

              {renderSideItem((index + 1) % slides.length)(
                slides[(index + 1) % slides.length]
              )}
            </RenderItemContainer>
          ))}
        </>
      ) : (
        <>
          {slides.map((slide: VideoShowCaseSlide, index: number) => (
            <RenderItemContainer key={index} active={index === currentSlide}>
              <div
                className="render-item-holder"
                style={{borderRadius: '16px', aspectRatio: '2/1'}}
              >
                {renderItem(slide, index, true)}
              </div>
            </RenderItemContainer>
          ))}
        </>
      )}
    </>
  )
}

export default React.memo(CarouselSliderComponent)
