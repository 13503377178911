import styled from 'styled-components'

interface StyledComponent {
  bgColor?: string
  labelColor?: string
  single?: boolean
}
export const StyledWrapper = styled.div<StyledComponent>`
  width: 100%;
  position: relative;

  cursor: pointer;

  .homepage-carousel-video-container {
    width: ${(props) => (props.single ? '100%' : 'auto')};
    height: 100%;
    cursor: pointer;
    @media (min-width: 768px) {
      aspect-ratio: 2/1;
    }
  }

  .homepage-carousel-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (min-width: 768px) {
      aspect-ratio: 2/1;
    }
  }

  .homepage-carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background: var(--brand-primary-black-light-color-o1);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: var(--dls-size-16);
    padding-right: var(--dls-size-16);
    padding-bottom: var(--dls-size-16);
  }

  .video-label-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .video-label {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-weight: var(--brand-font-weight-black);

    line-height: 27px;
    text-align: center;
    color: var(--brand-primary-white-color);
    margin-bottom: 12px;
  }

  .video-cta-btn {
    font-family: var(--brand-font-family-text);
    font-style: normal;
    font-size: var(--dls-size-16);
  }
  @media (max-width: 1023px) {
    .video-label-btn-container {
      flex: 1;
    }
  }
  @media (min-width: 768px) {
    .video-overlay {
      padding: var(--dls-size-16);
    }

    .video-label {
      font-size: 36px;
      line-height: 49px;
      font-weight: var(--brand-font-weight-heavy);
      margin-bottom: 24px;
    }
  }
`

export const StyledCta = styled.button<StyledComponent>`
  outline: none;
  align-self: stretch;
  padding: 11px 16px;
  gap: 10px;
  border-radius: 8px;
  font-family: var(--brand-font-family-text);
  font-style: normal;
  font-weight: var(--brand-font-weight-semiBold);
  font-size: 14px;
  line-height: 21px;
  background: ${({bgColor}) => (bgColor ? bgColor : 'transparent')};
  color: ${({labelColor}) =>
    labelColor ? labelColor : 'var(--brand-primary-white-color)'};
  border: 1px solid var(--brand-primary-white-color);
  border-color: ${({bgColor}) =>
    bgColor ? bgColor : 'var(--brand-primary-white-color)'};
  transition: all 0.2s ease-in-out;
  box-shadow: 8px 6px 8px 5px rgba(0, 0, 0, 0.15);

  &:hover {
    ${({bgColor}) => {
      if (!bgColor) {
        return `color: var(--brand-default-font-color);
    background: var(--brand-primary-white-color);`
      }
      return ''
    }}
  }

  @media (min-width: 768px) {
    padding: 16px 50px;
    gap: 10px;
    border-radius: var(--dls-size-16);
    font-size: 20px;
    line-height: 27px;
    width: auto;
  }
`
