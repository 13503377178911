import isEmpty from 'lodash/isEmpty'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Responsive from '../../../HOC/Responsive'
import CarouselSliderComponent from './CarouselSliderDesktop'
import CarouselSliderMobile from './CarouselSliderMobile'
import RenderItem from './Components/RenderItem'
import {CarouselDots, SliderWrapper} from './Slider.style'

const VideoShowCase = (props: VideoShowCase) => {
  const {isMobile = false, actions} = props || {}
  const {mobile = {}, desktop = {}, autoScrollTiming = 0} = props.state || {}

  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderContainerRef = useRef(null)

  const slides = useMemo(() => {
    return isMobile ? mobile?.slides || [] : desktop?.slides || []
  }, [isMobile, mobile, desktop])
  const [height, width] = isMobile
    ? [mobile?.height || '410', mobile?.width || '328']
    : [desktop?.height || '600', desktop?.width || '1440']

  const aspectRatio: string | undefined =
    typeof width === 'number' && typeof height === 'number'
      ? (width / height)?.toFixed(2)
      : undefined
  const intervalRef = useRef<number>()

  const startAutoPlay = useCallback(() => {
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    }, autoScrollTiming)

    return () => clearInterval(intervalRef.current)
  }, [autoScrollTiming, slides.length])

  useEffect(() => {
    if (document.readyState === 'complete') {
      startAutoPlay()
    } else {
      window.addEventListener('load', startAutoPlay)
      return () => window.removeEventListener('load', startAutoPlay)
    }
    return () => window.removeEventListener('load', startAutoPlay)
  }, [startAutoPlay, autoScrollTiming])

  const handleDotClick = useCallback(
    (index: number) => {
      setCurrentSlide(index)
    },
    [setCurrentSlide]
  )
  const renderItem = useCallback(
    (slide: VideoShowCaseSlide, index: number, single: boolean) => {
      const itemProps: IRenderItem = {
        video: slide.video || '',
        image: slide.image || '',
        cta: slide.cta,
        aspectRatio: aspectRatio,
        lazyLoad: index !== 0,
        actions,
        slideLabel: slide.slideLabel || '',
        posterImage: slide.posterImage || '',
        single: single,
        height: height,
        width: width,
      }
      return (
        <>
          <RenderItem key={index} {...itemProps} />
        </>
      )
    },
    [actions, aspectRatio]
  )

  const renderSideItem = useCallback(
    (index: number) => {
      return (slide: VideoShowCaseSlide) => {
        const itemProps: IRenderItem = {
          video: slide.video || '',
          image: slide.image || '',
          aspectRatio: aspectRatio,
          lazyLoad: index !== 0,
          actions: actions,
          slideLabel: slide.slideLabel || '',
          posterImage: slide.posterImage || '',
          autoPlay: false,
        }
        return <RenderItem key={index} {...itemProps} />
      }
    },
    [actions, aspectRatio]
  )

  const smoothScrollTo = useCallback(
    (index: number) => {
      const container = sliderContainerRef.current
      if (!container) {
        return
      }
      //@ts-ignore
      const scrollAmount: number = index * container.offsetWidth
      //@ts-ignore
      container.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      })
    },
    [sliderContainerRef]
  )
  const handlePrev = useCallback(() => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides.length) % slides.length
    )
    smoothScrollTo(currentSlide - 1)
    clearInterval(intervalRef.current)
  }, [currentSlide, slides.length, smoothScrollTo])

  const handleNext = useCallback(() => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    smoothScrollTo(currentSlide + 1)
    clearInterval(intervalRef.current)
  }, [currentSlide, slides.length, smoothScrollTo])

  const touchStartX = useRef(0)

  const handleTouchStart = useCallback(
    (e: any) => {
      touchStartX.current = e.touches[0].clientX
    },
    [touchStartX]
  )

  const handleTouchMove = useCallback(
    (e: any) => {
      if (touchStartX.current === 0) {
        return
      }

      const touchEndX = e.touches[0].clientX
      const deltaX = touchEndX - touchStartX.current

      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          handlePrev()
        } else {
          handleNext()
        }

        touchStartX.current = 0
      }
    },
    [touchStartX, handlePrev, handleNext]
  )

  const handleKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 37) {
        handlePrev()
      } else if (e.keyCode === 39) {
        handleNext()
      }
    },
    [handlePrev, handleNext]
  )
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  const handleTouchEnd = useCallback(() => {
    touchStartX.current = 0
  }, [touchStartX])

  if (isEmpty(props.state)) {
    return null
  }

  return (
    <SliderWrapper>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {!isMobile ? (
          <CarouselSliderComponent
            state={{slides, currentSlide}}
            actions={{handlePrev, handleNext, renderItem, renderSideItem}}
          />
        ) : (
          <CarouselSliderMobile
            state={{slides, currentSlide}}
            actions={{renderItem}}
          />
        )}
      </div>
      {slides.length >= 2 && (
        <div className="dots-container">
          {slides.map((slide, index) => (
            <CarouselDots
              key={index}
              active={index === currentSlide}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      )}
    </SliderWrapper>
  )
}

export default React.memo(
  withCustomErrorBoundary(Responsive(VideoShowCase), 'VideoShowCase')
)
