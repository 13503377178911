import styled from 'styled-components'

interface StyledComponent {
  active: boolean
}
export const SliderWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 46px;
  -webkit-tap-highlight-color: transparent;
  > * {
    overflow: hidden;
  }
  .dots-container {
    padding: 10px;
    display: flex;
    justify-content: center;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    align-items: center;
  }
`

export const CarouselDots = styled.span<StyledComponent>`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  background-color: ${({active}) =>
    active ? 'var(--category-primary-color)' : '#ccc'};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  @media (max-width: 768px) {
    width: ${({active}) => (active ? '15px' : '10px')};
    height: ${({active}) => (active ? '15px' : '10px')};
  }
`

export const RenderItemContainer = styled.div<StyledComponent>`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: center;
  max-height: 540px;

  > div {
    max-width: 1120px;
    flex: 1;
    aspect-ratio: 2/1;
    display: ${({active}) => (active ? 'flex' : 'none')};
  }

  > div:nth-child(2) {
    flex: 2;
    margin: 0 10px;
    aspect-ratio: 2/1;

    & > div {
      height: 100%;
      border-radius: var(--dls-size-16);
      overflow: hidden;
    }
  }

  > div:first-child,
  > div:last-child {
    height: auto;
    overflow: hidden;
  }
  > div:first-child {
    margin-right: var(--dls-size-24);
    border-radius: var(--dls-size-0) var(--dls-size-16) var(--dls-size-16)
      var(--dls-size-0);
    justify-content: flex-end;
  }
  > div:last-child {
    margin-left: var(--dls-size-24);
    border-radius: var(--dls-size-16) var(--dls-size-0) var(--dls-size-0)
      var(--dls-size-16);
  }

  .btn-icons {
    position: relative;
    width: 100%;

    .left-arrow,
    .right-arrow {
      position: absolute;
      z-index: 3;
      display: flex;
      align-items: center;
      width: 50px;
    }

    .left-arrow {
      left: 0;
    }

    .right-arrow {
      right: 0;
      justify-content: flex-end;
    }
    .render-item-holder {
      border-radius: var(--dls-size-16);
      aspect-ratio: 2;
    }
  }
`

export const RenderItemContainerMobile = styled.div<StyledComponent>`
  display: flex;
  justify-content: center;
  padding: var(--dls-size-0) var(--dls-size-16);

  > div {
    display: ${({active}) => (active ? 'flex' : 'none')};
  }

  .render-center-div {
    width: 100%;
    border-radius: var(--dls-size-16);
    overflow: hidden;
  }
`

export const ArrowIcon = styled.img`
  width: 60px;
  height: 60px;
`
