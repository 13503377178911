import noop from 'lodash/noop'
import React from 'react'

import {RenderItemContainerMobile} from './Slider.style'

const CarouselSliderMobile = (props: any) => {
  const {state, actions} = props || {}
  const {slides, currentSlide} = state || {}
  const {renderItem = noop} = actions || {}

  return (
    <>
      {slides.map((slide: VideoShowCaseSlide, index: number) => (
        <RenderItemContainerMobile key={index} active={index === currentSlide}>
          <div className="render-center-div">{renderItem(slide, index)}</div>
        </RenderItemContainerMobile>
      ))}
    </>
  )
}

export default React.memo(CarouselSliderMobile)
