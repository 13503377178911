import React from 'react'
import ExternalNudgeNow from './ExternalNudge'

function ExternalNudgeNowWidget({state}: any) {
  const {nudgeId, title} = state || {}
  if (!nudgeId) return null
  return <ExternalNudgeNow nudgeId={nudgeId} title={title} />
}

export default ExternalNudgeNowWidget
