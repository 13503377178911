import {useMemo, useCallback, useEffect, useState} from 'react'
import {useIntersection} from './useIntersection'

export function useShowScrollToTop() {
  const [showScrollToTopButton, setShowScrollToTopButton] = useState(false)

  const intersectionObserverOptions = useMemo(() => {
    return {
      rootMargin: '500px',
      threshold: 0,
      keepObserving: true,
    }
  }, [])

  const {nodeRef, isIntersecting} = useIntersection(intersectionObserverOptions)

  const handleShowScrollToTopButton = useCallback((value) => {
    setShowScrollToTopButton(value)
  }, [])

  useEffect(() => {
    handleShowScrollToTopButton(!isIntersecting)
  }, [handleShowScrollToTopButton, isIntersecting])

  return {
    nodeRef,
    showScrollToTopButton,
  }
}
