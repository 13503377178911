import dynamic from 'next/dynamic'

export const ProductFinder = dynamic(
  () =>
    import(
      /* webpackChunkName: "ProductFinder" */ '../shared/src/Screens/HomePage/ProductFinder'
    ),
  {
    ssr: true,
  }
)

export const BlogCards = dynamic(
  () =>
    import(
      /* webpackChunkName: "BlogCards" */ '../shared/src/Screens/HomePage/BlogCards'
    ),
  {
    ssr: true,
  }
)

export const ProcessSteps = dynamic(
  () =>
    import(
      /* webpackChunkName: "ProcessSteps" */ '../shared/src/Screens/CategoryPage'
    ).then((mod) => mod.ProcessSteps),
  {
    ssr: true,
  }
)

export const RichProductsCards = dynamic(
  () =>
    import(
      /* webpackChunkName: "RichProductsCards" */ '../shared/src/ComponentsV2/Screens/HomePage/RichProductsCards'
    ),
  {
    ssr: true,
  }
)

export const VideoShowCase = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideoShowCase" */ '../shared/src/ComponentsV2/Screens/HomePage/VideoShowCase'
    ),
  {
    ssr: true,
  }
)

export const ExpertsOnFingerPrints = dynamic(
  () =>
    import(
      /* webpackChunkName: "ExpertsOnFingerPrints" */ '../shared/src/ComponentsV2/Screens/LJ-PDP/ExpertsOnFingerPrints'
    ),
  {
    ssr: true,
  }
)

export const CategoryListing = dynamic(
  () =>
    import(
      /* webpackChunkName: "CategoryListing" */ '../shared/src/Screens/HomePage/BestSellers'
    ).then((mod) => mod.CategoryListing),
  {
    ssr: true,
  }
)

export const Team = dynamic(
  () =>
    import(
      /* webpackChunkName: "Team" */ '../shared/src/Screens/HomePage/Team'
    ),
  {
    ssr: true,
  }
)

export const CategoryGrid = dynamic(
  () =>
    import(
      /* webpackChunkName: "CategoryGrid" */ '../shared/src/Screens/HomePage/CategoryGrid'
    ),
  {
    ssr: true,
  }
)

export const HeroBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeroBanner" */ '../shared/src/Screens/HomePage/HeroBanner'
    ),
  {
    ssr: true,
  }
)

export const HowItWorks = dynamic(
  () =>
    import(
      /* webpackChunkName: "HowItWorks" */ '../shared/src/Screens/HomePage/HowItWorks'
    ),
  {
    ssr: true,
  }
)

export const Usp = dynamic(
  () =>
    import(/* webpackChunkName: "Usp" */ '../shared/src/Screens/HomePage/Usp'),
  {
    ssr: true,
  }
)

export const Testimonials = dynamic(
  () =>
    import(
      /* webpackChunkName: "Testimonials" */ '../shared/src/Screens/HomePage/Testimonials'
    ),
  {
    ssr: true,
  }
)

export const Reviews = dynamic(
  () =>
    import(
      /* webpackChunkName: "Reviews" */ '../shared/src/Screens/HomePage/Reviews'
    ),
  {
    ssr: true,
  }
)

export const PaymentOptions = dynamic(
  () =>
    import(
      /* webpackChunkName: "PaymentOptions" */ '../shared/src/Screens/HomePage/PaymentOptions'
    ),
  {
    ssr: true,
  }
)

export const DownloadApp = dynamic(
  () =>
    import(
      /* webpackChunkName: "DownloadApp" */ '../shared/src/Screens/HomePage/DownloadApp'
    ),
  {
    ssr: true,
  }
)

export const HomepageBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "HomepageBanner" */ '../shared/src/ComponentsV2/Screens/HomePage/HomepageBanner'
    ),
  {
    ssr: true,
  }
)

export const CategoryPalette = dynamic(
  () =>
    import(
      /* webpackChunkName: "CategoryPalette" */ '../shared/src/ComponentsV2/Screens/HomePage/CategoryPalette'
    ),
  {
    ssr: true,
  }
)

export const TrustMarkers = dynamic(
  () =>
    import(
      /* webpackChunkName: "TrustMarkers" */ '../shared/src/ComponentsV2/Screens/HomePage/TrustMarkers'
    ),
  {
    ssr: true,
  }
)

export const TrustMarkersV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "TrustMarkers" */ '../shared/src/ComponentsV2/Screens/HomePage/TrustMarkersV2'
    ),
  {
    ssr: true,
  }
)

export const Expert = dynamic(
  () =>
    import(
      /* webpackChunkName: "Expert" */ '../shared/src/ComponentsV2/Screens/HomePage/Expert'
    ),
  {
    ssr: true,
  }
)

export const WhyLittleJoys = dynamic(
  () =>
    import(
      /* webpackChunkName: "WhyLittleJoys" */ '../shared/src/ComponentsV2/Screens/HomePage/WhyLittleJoys'
    ),
  {
    ssr: true,
  }
)

export const InFocusBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "InFocusBanner" */ '../shared/src/ComponentsV2/Screens/HomePage/InFocusBanner'
    ),
  {
    ssr: true,
  }
)

export const VideoSection = dynamic(
  () =>
    import(
      /* webpackChunkName: "VideoSection" */ '../shared/src/ComponentsV2/Screens/HomePage/VideoSection'
    ),
  {
    ssr: true,
  }
)

export const FirstFoldCarousel = dynamic(
  () =>
    import(
      /* webpackChunkName: "FirstFoldCarousel" */ '../shared/src/ComponentsV2/Screens/HomePage/FirstFoldCarousel'
    ),
  {
    ssr: true,
  }
)

export const OurStory = dynamic(
  () =>
    import(
      /* webpackChunkName: "OurStory" */ '../shared/src/ComponentsV2/Screens/HomePage/OurStory'
    ),
  {
    ssr: true,
  }
)

export const Gallery = dynamic(
  () =>
    import(
      /* webpackChunkName: "OurStory" */ '../shared/src/ComponentsV2/Screens/HomePage/Gallery'
    ),
  {
    ssr: true,
  }
)

export const QualityCheck = dynamic(
  () =>
    import(
      /* webpackChunkName: "QualityCheck" */ '../shared/src/ComponentsV2/Screens/HomePage/QualityCheck'
    ),
  {
    ssr: true,
  }
)

export const CustomerReviews = dynamic(
  () =>
    import(
      /* webpackChunkName: "CustomerReviews" */ '../shared/src/ComponentsV2/Screens/HomePage/CustomerReviews'
    ),
  {
    ssr: true,
  }
)

export const TestimonialsOld = dynamic(
  () =>
    import(
      /* webpackChunkName: "CustomerReviews" */ '../shared/src/ComponentsV2/Screens/HomePage/Testimonials'
    ),
  {
    ssr: true,
  }
)

export const BirthdayTracker = dynamic(
  () =>
    import(
      /* webpackChunkName: "BirthdayTracker" */ '../shared/src/ComponentsV2/common/BirthdayTracker'
    ),
  {
    ssr: true,
  }
)

export const FeelingConfused = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeelingConfused" */ '../shared/src/Screens/CategoryPage'
    ).then((mod) => mod.FeelingConfused),
  {
    ssr: true,
  }
)

export const ConcernHelpFilters = dynamic(
  () =>
    import(
      /* webpackChunkName: "ConcernHelpFilters" */ '../shared/src/ComponentsV2/Screens/HomePage'
    ).then((mod) => mod.ConcernHelpFilters),
  {
    ssr: true,
  }
)

export const ComplianceProgress = dynamic(
  () =>
    import(
      /* webpackChunkName: "ConcernHelpFilters" */ '../../containers/UserComplianceProgress'
    ).then((mod) => mod.UserComplianceProgressContainer),
  {
    ssr: true,
  }
)
