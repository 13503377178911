import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'

import {getOptimizedImageKitLink} from '../../../../../helpers/getOptimizedImageKitLink'
import OptimizedImage from '../../../../common/OptimizedImage'
import {StyledCta, StyledWrapper} from './styles'

const RenderItem = (props: IRenderItem) => {
  const {
    video = '',
    image = '',
    cta,
    slideLabel = '',
    lazyLoad = true,
    height = '',
    width = 400,
    actions,
    posterImage = '',
    autoPlay = true,
    single = false,
  } = props

  const {handleCta = noop, handleAnalytics = noop} = actions || {}

  const handleCtaBtnClick = useCallback(
    (e: any) => {
      e.stopPropagation()
      handleAnalytics('videoShowCaseClicked', cta)
      handleCta(cta)
    },
    [cta, handleCta, handleAnalytics]
  )
  const handleImageClick = useCallback(() => {
    const {link, label} = cta || {}
    if (!label && !!link) {
      handleAnalytics('videoShowCaseClicked', cta)
      handleCta(cta)
    }
  }, [cta, handleAnalytics, handleCta])

  const showOverlayLayer = useMemo(() => {
    return !!slideLabel || !!cta?.label
  }, [slideLabel, cta?.label])

  const optimizedVideo = useMemo(() => {
    return getOptimizedImageKitLink(video, 'FULL', 'HALF', true)
  }, [video])

  return (
    <StyledWrapper onClick={handleImageClick} single={single}>
      <div className="homepage-carousel-video-container">
        {!!video ? (
          <video
            autoPlay={autoPlay}
            muted
            loop
            playsInline
            preload="metadata"
            width={width}
            height={height}
            className="homepage-carousel-video"
            poster={posterImage}
          >
            <source src={optimizedVideo} type="video/mp4" />
          </video>
        ) : (
          <OptimizedImage
            source={image}
            aspectWidth={width}
            aspectHeight={height}
            desktopViewWidth="FULL"
            mobileViewWidth="FULL"
            altText="banner"
            loading={lazyLoad ? 'lazy' : 'eager'}
            customClassName="homepage-carousel-image"
          />
        )}
      </div>
      {showOverlayLayer && (
        <div className="video-overlay">
          <div className="video-label-btn-container">
            <label className="video-label">{slideLabel}</label>
            {!!cta?.label && (
              <StyledCta
                className="video-cta-btn"
                onClick={handleCtaBtnClick}
                bgColor={cta.bgColor}
                labelColor={cta.labelColor}
              >
                {cta.label}
              </StyledCta>
            )}
          </div>
        </div>
      )}
    </StyledWrapper>
  )
}

export default React.memo(RenderItem)
