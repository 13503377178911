export {default} from './HomePage'
export {StyledHomePage} from './HomePage.styles'

export const StyledContainerWrap = ({children, sectionName}) => {
  return (
    <div className="content-wrap" data-scrollsection={sectionName}>
      <div className="container">{children}</div>
    </div>
  )
}
