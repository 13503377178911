import styled from 'styled-components'

export const StyledHomePage = styled.div`
  @media screen and (min-width: 768px) {
    .content-wrap {
      .container {
        max-width: 1120px;
        margin: 0 auto;
      }
      .container-extended {
        max-width: 1440px;
      }
    }
  }
`

export const StyledContainer = styled.div
