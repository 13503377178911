import styled from 'styled-components'

export const ScrollToTopButtonWrapper = styled.button`
  position: fixed;
  z-index: 5;
  right: 10px;
  bottom: 30px;
  font-size: 30px;
  line-height: 30px;
  padding: 6px;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--brand-primary-white-color);
  box-shadow: var(--brand-primary-black-light-color-o1) 2px 4px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  overflow: hidden;
`
