import {useCallback, useEffect, useRef, useState} from 'react'
import {analyticsTrigger} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

function useNudgeAnalytics() {
  const [isContentPresent, setIsContentPresent] = useState(false)
  const cleanupRef = useRef<(() => void) | null>(null)

  const leftArrowClick = useCallback(() => {
    analyticsTrigger(EVENT_MAP.NUDGE_STORY_TAP, {
      actionType: 'arrow',
      actionData: 'left arrow clicked',
    })
  }, [])

  const rightArrowClick = useCallback(() => {
    analyticsTrigger(EVENT_MAP.NUDGE_STORY_TAP, {
      actionType: 'arrow',
      actionData: 'right arrow clicked',
    })
  }, [])

  const handleNudgeClick = useCallback(
    (i: number) => () => {
      const nudgeCircularTitle =
        document.getElementsByClassName('nudgeCircularTitle')
      const text = nudgeCircularTitle[i].innerHTML
      analyticsTrigger(EVENT_MAP.NUDGE_STORY_CLICKED, {
        position: i + 1,
        text: text,
      })

      const cleanup = postNudgeClickEventLoader()
      if (cleanup) {
        cleanupRef.current = cleanup
      }
    },
    []
  )

  const postNudgeClickEventLoader = useCallback(() => {
    const leftArrow = document.querySelectorAll(
      '#nudgeFullPageStory>div>div.left-arrow'
    )
    const rightArrow = document.querySelectorAll(
      '#nudgeFullPageStory>div>div.right-arrow'
    )

    leftArrow.forEach((element) => {
      element.addEventListener('click', leftArrowClick)
    })

    rightArrow.forEach((element) => {
      element.addEventListener('click', rightArrowClick)
    })

    return () => {
      leftArrow.forEach((element) => {
        element.removeEventListener('click', leftArrowClick)
      })

      rightArrow.forEach((element) => {
        element.removeEventListener('click', rightArrowClick)
      })
    }
  }, [leftArrowClick, rightArrowClick])

  const nudgeEventLoader = useCallback(() => {
    const nudgeCircularContent = document.getElementsByClassName(
      'nudgeCircularContent'
    )
    for (let i = 0; i < nudgeCircularContent.length; i++) {
      nudgeCircularContent[i].addEventListener('click', handleNudgeClick(i))
    }

    return () => {
      for (let i = 0; i < nudgeCircularContent.length; i++) {
        nudgeCircularContent[i].removeEventListener(
          'click',
          handleNudgeClick(i)
        )
      }
    }
  }, [handleNudgeClick])

  useEffect(() => {
    if (isContentPresent) {
      const cleanup = nudgeEventLoader()
      return () => {
        cleanup()
        if (cleanupRef.current) {
          cleanupRef.current()
        }
      }
    }
  }, [isContentPresent, nudgeEventLoader])

  useEffect(() => {
    const nudgeStories = document.getElementById('nudge_stories')

    if (nudgeStories) {
      const observer = new MutationObserver(() => {
        setIsContentPresent(true)
      })

      observer.observe(nudgeStories, {childList: true})

      return () => observer.disconnect()
    }
  }, [])
}

export default useNudgeAnalytics
